<template>
  <div>
    <div id="app" :class="{ darkClass: darkMode }">
      <appcomponent></appcomponent>
      <sidebarcomponent ref="sidebar" :collapsed="collapsed" @collapseSidebar="collapse()">
        <div slot="links">
          <router-link to="/dashboard" @click.native="_closeDropRight">
            <b-icon icon="home-outline" :class="{ 'mr-3': !collapsed }" />
            <span class="link-text">Dashboard</span>
          </router-link>
        </div>
      </sidebarcomponent>
      <transition name="fade" mode="out-in">
        <div id="router-view" :class="{ 'router-collapsed': collapsed }">
          <subbarcomponent></subbarcomponent>
          <RouterView />
        </div>
      </transition>
    </div>
    <footercomponent v-show="footerShow"></footercomponent>
    <darkmodecomponent></darkmodecomponent>
    <cookie-law>
      <div slot-scope="props" style="width: 100%">
        Ikleeralles.nl maakt gebruik van Cookies.
        <router-link to="/pp.pdf" target="_blank">Meer informatie</router-link>
        <b-button class="is-pulled-right" type="is-success" @click="props.accept">Begrepen</b-button>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import { EventBus } from "@/common/event-bus"
import { mapState } from "vuex"

import CookieLaw from "vue-cookie-law"

export default {
  components: {
    "cookie-law": CookieLaw,
  },
  data() {
    return {
      collapsed: false,
    }
  },
  mounted() {
    this.init()
    this.setPremiumModalListener()
  },
  methods: {
    _closeDropRight() {
      this.$refs.sidebar.closeDropRight()
    },
    setPremiumModalListener() {
      EventBus.$on("showPremium", (customMessage = "") => {
        if (customMessage == "") {
          customMessage = `Voor deze optie heb je premium nodig. Probeer nu <strong>gratis</strong> 7 dagen!`
        }
        return this.$buefy.dialog.confirm({
          title: "Premium optie",
          message: customMessage,
          hasIcon: true,
          icon: "exclamation-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          cancelText: "Sluiten",
          confirmText: "Bekijken",
          onConfirm: () => this.$router.push("/premium"),
        })
      })
    },
    async init() {
      await this.$store.dispatch("app/init");
      await this.$store.dispatch("user/init");
      this.isLoading = false

      let refferal = this.$route.query.r
      if (typeof refferal !== "undefined") {
        $cookies.set("refferal", refferal)
      }
    },
    collapse() {
      this.collapsed = !this.collapsed
    },
  },
  computed: {
    footerShow() {
      if (this.$route.path == "/" || this.$route.path === "/woordjes-leren" || this.$route.path === "/wrts-alternatief") {
        return true
      }
    },
    username() {
      return this.$store.state.user.username.toLowerCase()
    },
    darkMode() {
      return this.$store.state.app.darkMode
    },
    watch: {
      watch: {
        darkMode(newDark) {
          if (newDark) {
            document.body.style.background = "#333333"
          } else {
            document.body.style.background = "#f0f0f0"
          }
        },
      },
    },
  },
}
</script>
<style lang="scss" scoped>
#router-view {
  margin-left: 250px;
  transition: all 0.3s;
  &.router-collapsed {
    margin-left: 40px;
  }
  @media only screen and (max-width: 1000px) {
    margin-left: 40px;
  }
}
</style>
