import store from '@/store'

export default [
  {
    path: '/premium',
    name: 'premium',
    component: () => lazyLoadView(import('@/views/Premium.vue')),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => lazyLoadView(import('@/views/Dashboard.vue')),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/test/:year/:level/:topic',
    name: 'test',
    props: true,
    component: () => lazyLoadView(import('@/views/Test.vue')),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create',
    name: 'create',
    component: () => lazyLoadView(import('@/views/Create.vue')),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: require('@/views/404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '*',
    redirect: '/404'
  }
]

function lazyLoadView(AsyncView) {
  let AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@/views/Loading.vue').default,
    delay: 400,
    error: require('@/views/Timeout.vue').default,
    timeout: 5000
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    }
  })
}
