import Vue from 'vue'
import Vuex from 'vuex'
import ApiService from "@/common/api.service"
import axios from 'axios'
import cookie from 'vue-cookies'
import { init } from 'aos'
// import { SET_TOKEN } from './mutation.type'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    token: null,
    loggedIn: false,
    username: "",
    role: "student",
    hasPremium: false,
    premiumType: "standaard",
    year: 1,
    level: "vwo",
    folders: [],
    groups: [],
  },
  mutations: {
    setLoggedIn(state, data) {
      state.loggedIn = data
    },
    setUsername(state, data) {
      state.username = data
    },
    setPremium(state, data) {
      state.hasPremium = data
    },
    setOldPremium(state, data) {
      state.oldPremium = data
    },
    setPremiumType(state, data) {
      state.premiumType = data
    },
    setEducation(state, data) {
      state.year = data.year
      state.level = data.level
    },
    setFolders(state, data) {
      state.folders = data
    },
    setGroups(state, data) {
      state.groups = data
    },
  },
  actions: {
    async init(context) {
      console.log('hier?')
      context.dispatch("refreshFolders")
      context.dispatch("refreshGroups")
      context.dispatch("getPremiumType")
    },
    async update(context) {
      let response = await ApiService.get("/me")
      if (!response) {
        context.commit('setLoggedIn', false)
      } else {
        context.commit('setLoggedIn', true)
        context.commit('setPremium', response.data.hasPremium)
        context.commit('setOldPremium', response.data.oldPremium)
        context.commit('setUsername', response.data.username)
        context.commit('setEducation', { year: response.data.year, level: response.data.level })
      }
    },
    async getPremiumType(context) {
      let response = await ApiService.get("/user/premium")

      context.commit('setPremiumType', response.data.subscriptionType)
    },
    async logout(context) {
      await ApiService.get("/user/logout")

      context.commit('setLoggedIn', false)
      context.commit('setUsername', "")
    },
    async refreshFolders(context) {
      const response = await ApiService.get("folder/me")
      context.commit('setFolders', response.data)
    },
    async refreshGroups(context) {
      const response = await ApiService.get("group/me")
      context.commit('setGroups', response.data)
    }
  }
}
