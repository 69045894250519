import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from '@/router/routes'
import NProgress from 'nprogress/nprogress'
import { SET_ADDEDROUTES } from '../store/App/mutation.type'
import { ToastProgrammatic as Toast } from 'buefy'
import { route } from 'ikleeralles-components/routes.js'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

if (!store.state.app.addedRoutes) {
  console.log(router)
  router.addRoute(route)
  store.commit(`app/${SET_ADDEDROUTES}`, true)
}

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  document.title = routeTo.meta.title
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start()
  }

  await store.dispatch('user/update')

  // Check if auth is required on this route
  // (including nested routes).
  const requiresAuth = routeTo.matched.some((route) => route.meta.requiresAuth)

  // If auth isn't required for the route, just continue.
  if (!requiresAuth) return next()

  if(routeTo.meta.requiresAdmin && store.state.user.username !== "webmaster") {
    Toast.open({
      type: 'is-danger',
      message: 'Niet geautoriseerd',
      duration: 4000
    })
    return router.push('/not-found')
  }

  if (!store.state.user.loggedIn) { //Retrieve loggedin state from user store
    Toast.open({
      type: 'is-danger',
      message: 'Je moet ingelogd zijn om deze pagina te bekijken',
      duration: 4000
    })
    return router.push('/not-found')
  }

  return next()

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
