<script>
export default {
}
</script>
<template>
  <div class="container">
    <h1 class="title is-3 has-text-centered">404 Not found</h1>
    <p class="has-text-centered">Deze pagina lijkt niet te bestaan</p>
  </div>
</template>
<style lang="scss" scoped>
.container {
  padding-top: 5%;
}
</style>