import Vue from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';
import Buefy from 'buefy';
import VueCookies from 'vue-cookies';
import './vee-validate';
import AOS from 'aos';
import 'aos/dist/aos.css';

import ikleerallescomponents from 'ikleeralles-components';
import VueGtag from "vue-gtag";
import VueMathjax from 'vue-mathjax';

//Install lodash and add to vue prototype
import lodash from 'lodash';
Vue.prototype._ = _;

import ApiService from './common/api.service';
import './common/filters';

Vue.config.productionTip = process.env.NODE_ENV === 'production';

Vue.use(Buefy);
Vue.use(ikleerallescomponents);
Vue.use(VueCookies);

Vue.use(VueGtag, {
  config: { id: "UA-47699187-8" }
}, router);
Vue.use(VueMathjax);


Vue.$cookies.config("7d", null, process.env.VUE_APP_COOKIE_DOMAIN);

ApiService.init();
AOS.init({
  duration: 600,
  once: true,
});

const app = new Vue({
  router: router,
  store: store,
  render: (h) => h(App),
}).$mount('#app');
