import Vue from 'vue';
import Vuex from 'vuex';

import app from './App';
import user from './User';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app: app,
        user: user,
    }
});
