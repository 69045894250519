import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import ApiService from "@/common/api.service"

import { DialogProgrammatic as Dialog } from 'buefy'
import { TOGGLE_DARKMODE, SET_DARKMODE, SET_ADDEDROUTES } from "./mutation.type"

Vue.use(Vuex)
Vue.use(VueCookies)

Vue.$cookies.config("7d", null, process.env.VUE_APP_COOKIE_DOMAIN);

export default {
  namespaced: true,
  state: {
    isMobile: false,
    languages: [],
    educationLevels: [],
    darkMode: false,
    addedRoutes: false,
  },
  mutations: {
    setMobile(state, data) {
      state.isMobile = data
    },
    setLanguages(state, data) {
      state.languages = data
    },
    setEducationLevels(state, data) {
      state.educationLevels = data
    },
    [TOGGLE_DARKMODE](state) {
      state.darkMode = !state.darkMode
      $cookies.set("darkMode", state.darkMode)
    },
    [SET_DARKMODE](state, data) {
      state.darkMode = data
    },
    [SET_ADDEDROUTES](state, data) {
      state.addedRoutes = data
    }
  },
  actions: {
    async init(context) {
      let isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone/i.test(navigator.userAgent)) ? true : false
      context.commit('setMobile', isMobile)
      await context.dispatch("getLanguages")
      await context.dispatch("getEducationlevels")

      context.commit(SET_DARKMODE, $cookies.get("darkMode") == "true")
    },
    async getLanguages(context) {
      let response = await ApiService.get("translate/languages", false)
      context.commit('setLanguages', response.data)
      return
    },
    async getEducationlevels(context) {
      let response = await ApiService.get("levels", false)
      context.commit('setEducationLevels', response.data)
      return
    },
  }
}
