import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import {
  ToastProgrammatic as Toast,
  LoadingProgrammatic as loading
} from 'buefy'

const token = $cookies.get("token")

export default {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_API
    axios.defaults.withCredentials = true
    axios.defaults.headers.common = {
      'Authorization': 'Bearer ' + token
    }
  },
  async get(url, load = false, auth = true) {
    var loadingcomponent
    if (load) {
      loadingcomponent = loading.open()
    }
    try {
      let response
      if (!auth) {
        axios.defaults.headers.common = {}
        response = await axios.get(`${url}`)
        axios.defaults.headers.common = {
          'Authorization': 'Bearer ' + token
        }
      } else {
        response = await axios.get(`${url}`)
      }
      if (load) {
        loadingcomponent.close()
      }
      return response
    } catch (error) {
      if (load) {
        loadingcomponent.close()
      }
      if (error.response.status === 401 || error.response.status === 404) {
        return false
      } else {
        throw new Error(`ApiService ${error}`)
      }
    }
  },
  async post(url, params, load = true, auth = true) {
    var loadingcomponent
    if (load) {
      loadingcomponent = loading.open()
    }
    try {
      if (!auth) {
        axios.defaults.headers.common = {}
        var response = await axios.post(`${url}`, params)
        axios.defaults.headers.common = {
          'Authorization': 'Bearer ' + token
        }
      } else {
        var response = await axios.post(`${url}`, params)
      }
      if (load) {
        loadingcomponent.close()
      }
      return response
    } catch (error) {
      if (load) {
        loadingcomponent.close()
      }
      if (error.response.status === 401 || error.response.status === 404 || error.response.status === 400) {
        return error.response
      } else {
        throw new Error(`ApiService ${error}`)
      }
    }
  },
  getBase() {
    return axios.defaults.baseURL
  }
}
