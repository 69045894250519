import Vue from 'vue'
import store from '../store/index'
import moment from "moment"

Vue.filter("languageLookup", function(abbreviation) {
  let language = _.find(store.state.app.languages, { code: abbreviation })
  if(!language) {
    return language
  }
  return language.name
})

Vue.filter("formatDate", function(date) {
  return moment(date).lang("nl").fromNow()
})

Vue.filter('ucfirst', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})